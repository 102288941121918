import { createApp } from 'vue'
import App from './App.vue'
// import router from './router'

// import axios from 'axios'
// Vue.prototype.$http = axios;
// Vue.config.productionTip = false
// Vue.use(VueRouter);

// createApp(App).use(router).mount('#app')
createApp(App)
    .mount('#app');