<script>
	import { httpGet } from '../assets/js/utils.js'

	export default {
		props: ['fixture', 'selected_tab', 'limit'],
		emits: ['updateOddsVisibility'],
		components: {
		},
		watch: {
			limit: function() {
				this.getData()
			}
		},
		data() {
			return {
				oddsData: [],
				isLoading: false,
				hasOdds: true,
				fullOdds: null
			};
		},
		methods: {
			async getData() {
				try {
					this.isLoading = true;
					this.bookmakerId = 6;

					await this.$nextTick();
					let url = `${process.env.VUE_APP_BACKEND_URL}/api/bestbets/odds/${this.fixture}`;
					if (this.bookmakerId) {
						url += `?bookmakerId=${this.bookmakerId}`;
					}

					this.oddsData = await httpGet(url);
					this.hasOdds = this.oddsData.length > 0;
					this.$emit('updateOddsVisibility', this.hasOdds);
					// console.log(this.oddsData)
				} catch (error) {
					// Hide the odds component if there is an error
					console.error(error);
					this.hasOdds = false;
					this.$emit('updateOddsVisibility', this.hasOdds);
				} finally {
					this.isLoading = false;
				}
			},
			getUpdatedDate(updateTime) {
				var date = new Date(updateTime);
				var month = date.toLocaleString('default', { month: 'long' });
				var day = date.getDate()
				return day + " " + month
			},
			getUpdatedTime(updateTime) {
				var date = new Date(updateTime);
				var hours = (date.getHours() == "0" ? "00" : date.getHours())
				var minutes = (parseInt(date.getMinutes()) < 10 ? "0" + date.getMinutes() : date.getMinutes())
				return hours + ':' + minutes;
			},
			showFullOdds() {
				this.fullOdds = !this.fullOdds;
			}
		},
		created() {
			this.getData()
		}
	};
</script>

<template>
	<div class="full-odd-card" v-if="this.fullOdds">
		<div class="full-odd-title" :class="{ folded: this.fullOdds, defolded: !this.fullOdds}">
			<div>
				<!-- <img src="../assets/img/statty-logo-small.png"> -->
				<!-- <p>All odds</p> -->
			</div>
			<div>
				<p>Last updated: {{ this.getUpdatedDate(oddsData[0]?.lastRapidUpdate + "Z") }} at {{ this.getUpdatedTime(oddsData[0]?.lastRapidUpdate + "Z") }}</p>
			</div>
		</div>
		<div class="full-odd-animation" :class="{ folded: this.fullOdds, defolded: !this.fullOdds}">
			<div class="odds-container">
				<p>Moneyline</p>
				<div class="odds-group-container">
					<div class="odd-group odd-33">
						<p>Home</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.home }}</p></div>
					</div>
					<div class="odd-group odd-33">
						<p>Draw</p>					
						<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.draw }}</p></div>
					</div>
					<div class="odd-group odd-33">
						<p>Away</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.away }}</p></div>
					</div>
				</div>
			</div>
			<div class="odds-container">
				<p>Double chance</p>
				<div class="odds-group-container">
					<div class="odd-group odd-33">
						<p>1X</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.doubleChance?.home_away }}</p></div>
					</div>
					<div class="odd-group odd-33">
						<p>12</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.doubleChance?.home_draw }}</p></div>
					</div>
					<div class="odd-group odd-33">
						<p>X2</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.doubleChance?.draw_away }}</p></div>
					</div>
				</div>
			</div>
			<div class="odds-container">
				<p>Both teams to score</p>
				<div class="odds-group-container">
					<div class="odd-group odd-50">
						<p>Yes</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.bothTeamsToScore?.yes }}</p></div>
					</div>
					<p class="btts-label">BTTS</p>
					<div class="odd-group label-after odd-50">
						<div class="odd"><p>{{ oddsData[0]?.bothTeamsToScore?.no }}</p></div>
						<p>No</p>
					</div>
				</div>
			</div>
			<div class="odds-container">
				<p>Total goals</p>
				<!-- <div class="odds-group-container">
					<div class="odd-group odd-50">
						<p>Over 0.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_zero_five }}</p></div>
					</div>
					<div class="odd-group odd-50">
						<p>Under 0.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.under_zero_five }}</p></div>
					</div>
				</div> -->
				<div class="odds-group-container">
					<div class="odd-group odd-50">
						<p>Over 1.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_one_five }}</p></div>
					</div>
					<div class="odd-group odd-50">
						<p>Under 1.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.under_one_five }}</p></div>
					</div>
				</div>
				<div class="odds-group-container">
					<div class="odd-group odd-50">
						<p>Over 2.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_two_five }}</p></div>
					</div>
					<div class="odd-group odd-50">
						<p>Under 2.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.under_two_five }}</p></div>
					</div>
				</div>
				<div class="odds-group-container">
					<div class="odd-group odd-50">
						<p>Over 3.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_three_five }}</p></div>
					</div>
					<div class="odd-group odd-50">
						<p>Under 3.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.under_three_five }}</p></div>
					</div>
				</div>
				<div class="odds-group-container">
					<div class="odd-group odd-50">
						<p>Over 4.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_four_five }}</p></div>
					</div>
					<div class="odd-group odd-50">
						<p>Under 4.5</p>
						<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.under_four_five }}</p></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="odd-card close-full-odds" v-if="this.fullOdds">
		<div class="odds-container" @click="showFullOdds">
			<h3>Close odds</h3>
			<img src="../assets/img/close-white.png" />
		</div>
	</div>
	<div class="odd-card" v-if="selected_tab === 'last5' && !this.fullOdds">
		<div class="odds-small-container">
			<div class="odds-open-full" @click="showFullOdds">
				<p>All odds</p>
				<img src="../assets/img/share.png" class="sorting-arrow">
			</div>
			<div class="odds-group-container">
				<div class="odd-group odd-33">
					<p>1</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.home }}</p></div>
				</div>
				<div class="odd-group draw odd-33">
					<p>X</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.draw }}</p></div>
				</div>
				<div class="odd-group odd-33">
					<p>2</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.matchWinner?.away }}</p></div>
				</div>
			</div>
		</div>
	</div>
	<div class="odd-card" v-if="selected_tab === 'goals' && !this.fullOdds">
		<div class="odds-small-container">
			<div class="odds-open-full small" @click="showFullOdds">
				<img src="../assets/img/share.png" class="sorting-arrow">
			</div>
			<div class="odds-group-container">
				<div class="odd-group divider">
					<p>o2.5</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.goalsOverUnder?.over_two_five }}</p></div>
				</div>
				<div class="odd-group">
					<p>Yes</p>
					<div class="odd label-for"><p>{{ oddsData[0]?.bothTeamsToScore?.yes }}</p></div>
				</div>
				<p class="btts-label">BTTS</p>
				<div class="odd-group label-after">
					<div class="odd"><p>{{ oddsData[0]?.bothTeamsToScore?.no }}</p></div>
					<p>No</p>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="odd-card" v-if="selected_tab === 'shots'">
		<div class="odds-small-container">
			<div class="odd-group divider">
				<div class="odd label-after"><p>1.79</p></div>
				<p>Over 25.5 shots</p>
			</div>
			<div class="odd-group">
				<div class="odd label-after"><p>1.79</p></div>
				<p>Over 8.5 OT</p>
			</div>
		</div>
	</div> -->
</template>

<style scoped src="../assets/css/style.css">
</style>