<script>
	export default {
			props: ['percentageHome', 'percentageAway', 'label']
	};
</script>

<template>
	<div class="perc-row">
		<div class="perc-indicator">
			<div v-bind:class="`perc-indicator-${percentageHome.toFixed(1) * 100}`" :style="{ width: percentageHome * 100 + '%' }">
			</div>
		</div>
		<div class="perc-value">
			<p>{{ Math.round(percentageHome * 100)}}%</p>
		</div>
		<div class="perc-label">
			<p>{{ label }}</p>
		</div>
		<div class="perc-value">
			<p>{{ Math.round(percentageAway * 100)}}%</p>
		</div>
		<div class="perc-indicator">
			<div v-bind:class="`perc-indicator-${percentageAway.toFixed(1) * 100}`" :style="{ width: percentageAway * 100 + '%' }">
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>
