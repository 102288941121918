<script>
export default {
	props: ['standings'],
	methods: {
		handleImageError(event) {
			event.target.src = require('../assets/img/DefaultTeamLogo.png'); // Path to your local image
		}
	}
};
</script>


<template>
	<div v-if="!standings.length" class="stnd-not-available">
		<div class="greyheader">
			<div class="stnd-name">
				<p class="title">Standings</p>
			</div>
		</div>
		<div class="message">
			<img src="../assets/img/stnd-icon.png">
			<h3>No standings available</h3>
		</div>
	</div>
	<div v-if="standings.length" class="stnd">
		<div class="stnd-row stnd-title greyheader">
			<div class="stnd-name">
				<p class="title">Standings</p>
			</div>
			<div class="stnd-1 stnd-pts">
				<p>Pt</p>
			</div>
			<div class="stnd-1">
				<p>P</p>
			</div>
			<div class="stnd-form">
				<p>W</p>
				<p class="score-divider">-</p>
				<p>D</p>
				<p class="score-divider">-</p>
				<p>L</p>
			</div>
			<div class="stnd-goals">
				<p>GF</p>
				<p class="score-divider">:</p>
				<p>GA</p>
			</div>
		</div>
		<div class="stnd-row stnd-data" v-for="(standing) in standings" :key="standing.rank">
			<div class="stnd-rank">
				<p>{{ standing.rank }}</p>
			</div>
			<div class="stnd-logo">
				<img class="logo-flag" v-bind:src="standing.logo" alt="logo-home" @error="handleImageError"/>
			</div>
			<div class="stnd-name">
				<p>{{ standing.name }}</p>
			</div>
			<div class="stnd-1 stnd-pts">
				<p>{{ standing.points }}</p>
			</div>
			<div class="stnd-1">
				<p>{{ standing.played }}</p>
			</div>
			<div class="stnd-form">
				<p>{{ standing.win }}</p>
				<p class="score-divider">-</p>
				<p>{{ standing.draw }}</p>
				<p class="score-divider">-</p>
				<p>{{ standing.lose }}</p>
			</div>
			<div class="stnd-goals">
				<p>{{ standing.goalsFor }}</p>
				<p class="score-divider">:</p>
				<p>{{ standing.goalsAgainst }}</p>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>
