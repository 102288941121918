<script>
		export default {
				props: ['totalShotsHome', 'totalShotsAway', 'onTargetShotsHome', 'onTargetShotsAway', 'label']
		};
</script>

<template>
	<div class="stats-row">
		<div class="avg-box shots-value" v-bind:class="`shots-stats-color-${totalShotsHome * 10}`">
			<p>{{ totalShotsHome }}</p>
		</div>
		<div class="avg-box ot-shots-value" v-bind:class="`ot-shots-stats-color-${onTargetShotsHome * 10}`">
			<p>{{ onTargetShotsHome }}</p>
		</div>
		<div class="stats-label">
			<p>{{ label }}</p>
		</div>
		<div class="avg-box shots-value" v-bind:class="`shots-stats-color-${totalShotsAway * 10}`">
			<p>{{ totalShotsAway }}</p>
		</div>
		<div class="avg-box ot-shots-value" v-bind:class="`ot-shots-stats-color-${onTargetShotsAway * 10}`">
			<p>{{ onTargetShotsAway }}</p>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css"></style>
