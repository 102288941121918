<script>
	export default {
		props: ['totalCornersForHome', 'totalCornersForAway', 'totalCornersAgainstHome', 'totalCornersAgainstAway', 'label'],
		methods: {
			calculateBackgroundColor(value) {
			if (value <= 2.5) {
				return '#FDBCBC26';
			} else if (value <= 5.0) {
				return '#FDBCBC73';
			} else if (value <= 7.5) {
				return '#FDBCBCB3';
			} else if (value <= 10.0) {
				return '#FDBCBCE6';
			} else if (value <= 12.0) {
				return '#F99D9DB3';
			} else {
				return '#F99D9D';
			}
			},
			calculateFontWeight(value) {
			if (value <= 7.50) {
				return 400;
			} else {
				return 500;
			}
			}
		}
	};
</script>

<template>
	<div class="stats-row">
		<div class="avg-box corners-value" :style="{ backgroundColor: calculateBackgroundColor(totalCornersForHome), fontWeight: calculateFontWeight(totalCornersForHome) }">
			<p>{{ totalCornersForHome }}</p>
		</div>
		<div class="avg-box corners-value" :style="{ backgroundColor: calculateBackgroundColor(totalCornersAgainstHome), fontWeight: calculateFontWeight(totalCornersAgainstHome) }">
			<p>{{ totalCornersAgainstHome }}</p>
		</div>
		<div class="stats-label">
			<p>{{ label }}</p>
		</div>
		<div class="avg-box corners-value" :style="{ backgroundColor: calculateBackgroundColor(totalCornersForAway), fontWeight: calculateFontWeight(totalCornersForAway) }">
			<p>{{ totalCornersForAway }}</p>
		</div>
		<div class="avg-box corners-value" :style="{ backgroundColor: calculateBackgroundColor(totalCornersAgainstAway), fontWeight: calculateFontWeight(totalCornersAgainstAway) }">
			<p>{{ totalCornersAgainstAway }}</p>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css"></style>