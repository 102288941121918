<script>
	export default {
			props: ['date'],
			data() {
				return {
					countdown: '',
					countdownInterval: null,
					showLiveTag: false
				};
			},
			methods: {
				getCountdownTime() {
					const currentTime = new Date();
					const startTime = new Date(this.date + "Z");
					const timeDifference = startTime - currentTime;

					if (timeDifference > 15 * 60 * 1000) {
						return 'vs.'
					} else if (timeDifference <= 0) {
						clearInterval(this.countdownInterval);
						this.showLiveTag = true
					} else {
						const minutes = Math.floor(timeDifference / 60000);
						const seconds = Math.floor((timeDifference % 60000) / 1000);
				
						return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
					}
				},
			},	
			mounted() {
				this.countdownInterval = setInterval(() => {
					const currentTime = new Date();
					const startTime = new Date(this.date + "Z");
					const timeDifference = startTime - currentTime;

					if (timeDifference < 15 * 60 * 1000) {
						this.$forceUpdate();
					} else if (timeDifference <= 0) {
						return
					}
				}, 1000);
			},
			beforeUnmount() {
				clearInterval(this.countdownInterval);
			}
	};
</script>


<template>
  <div>
	<div class="live-tag" v-if="showLiveTag"><p>Live</p></div>
	<h3 v-if="!showLiveTag" class="countdown-timer">{{ getCountdownTime(this.date + "Z") }}</h3>
  </div>
</template>

<style scoped src="../assets/css/style.css"></style>
