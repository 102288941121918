<script>
	export default {
		props: ['team', 'playerStats'],
		data() {
			return {
				sortColumn: 'date',
				sortOrder: 'desc',
			};
		},
		computed: {
			sortedPlayerStats() {
				const statsCopy = [...this.playerStats];
				if (this.sortColumn) {
					statsCopy.sort((a, b) => {
					const valueA = a[this.sortColumn];
					const valueB = b[this.sortColumn];
					if (this.sortOrder === 'asc') {
						return valueA - valueB;
					} else {
						return valueB - valueA;
					}
					});
				}
				return statsCopy;
			},
		},
		methods: {
			sortPlayers(column) {
			if (this.sortColumn === column) {
				this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
			} else {
				this.sortColumn = column;
				this.sortOrder = 'desc';
			}
			},
		}
	};
</script>

<template>
	<div class="player-match" v-for="fixture in sortedPlayerStats" :key="fixture.match" v-bind:class="(fixture.minutes > 0)?'played':'not-played'">
		<div class="color" v-if="(team == fixture.homeTeam && fixture.homeGoals > fixture.awayGoals) || (team == fixture.awayTeam && fixture.awayGoals > fixture.homeGoals)" id="green"></div>
		<div class="color" v-else-if="fixture.homeGoals == fixture.awayGoals" id="grey"></div>
		<div class="color" v-if="(team == fixture.homeTeam && fixture.homeGoals < fixture.awayGoals) || (team == fixture.awayTeam && fixture.awayGoals < fixture.homeGoals)" id="red"></div>
		<div class="match">
			<div class="match-info">
				<div class="match-result">
					<div class="score">
						<div class="score-h">
							<p>{{ fixture.homeGoals }}</p>
						</div>
						<div class="score-a">
							<p>{{ fixture.awayGoals }}</p>
						</div>
					</div>
					<div class="teams">
						<div class="team-h">
							<p>{{ fixture.homeTeam }}</p>
						</div>
						<div class="team-a">
							<p>{{ fixture.awayTeam }}</p>
						</div>
					</div>
				</div>
				<div class=" cards value">
					<div class="yellow-cards cardsApplicable">
						<div v-if="fixture.yellow === 1" class="card-yellow"></div>
						<div v-if="fixture.red === 1" class="card-red"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="player-match-box minutes value">
			<p v-if="fixture.minutes > 0">{{ fixture.minutes }}'</p>
			<div class="minutes-start" v-if="fixture.minutes > 0 && !fixture.substitute" :style="{ width: 'calc(' + fixture.minutes + 'px /2)'}"></div>
			<div class="minutes-end" v-if="fixture.minutes > 0 && fixture.substitute" :style="{ width: 'calc(' + fixture.minutes + 'px /2)'}"></div>
			<img v-if="fixture.minutes === null || fixture.minutes === 0" src="../assets/img/bench.png" />
		</div>
		<div class="player-match-box shots" v-bind:class="(fixture.shots > 0)?'value':'no-value'">
			<p v-if="fixture.shots > 0">{{ fixture.shots }}</p>
			<p v-if="fixture.shots === null || fixture.shots === 0">-</p>
		</div>
		<div class="player-match-box ot" v-bind:class="(fixture.onTarget > 0)?'value':'no-value'">
			<p v-if="fixture.onTarget > 0">{{ fixture.onTarget }}</p>
			<p v-if="fixture.onTarget === null || fixture.onTarget === 0">-</p>
		</div>
		<div class="player-match-box assist" v-bind:class="(fixture.assists > 0)?'value':'no-value'">
			<p v-if="fixture.assists > 0">{{ fixture.assists }}</p>
			<p v-if="fixture.assists === null || fixture.assists === 0">-</p>
		</div>
		<div class="player-match-box goals" v-bind:class="(fixture.goals > 0)?'value':'no-value'">
			<p v-if="fixture.goals > 0">{{ fixture.goals }}</p>
			<p v-if="fixture.goals === null || fixture.goals === 0">-</p>
		</div>
	</div>
</template>
<style scoped src="../assets/css/style.css"></style>