<script>
	import playerInfoComponent from './playerInfoComponent.vue'

	export default {
		props: ['playerStats', 'limit', 'team'],
		components: {
			playerInfoComponent
		},
		data() {
			return {
				sortColumn: 'onTarget',
				sortOrder: 'desc',
				playerInfoPop: false,
				selectedPlayer: null
			};
		},
		computed: {
			sortedPlayerStats() {
				const statsCopy = [...this.playerStats];

				if (this.sortColumn) {
					statsCopy.sort((a, b) => {
						const valueA = a.averages[this.sortColumn];
						const valueB = b.averages[this.sortColumn];

						if (this.sortOrder === 'asc') {
							return valueA - valueB;
						} else {
							return valueB - valueA;
						}
					});
				}

				return statsCopy;
			},
		},
		methods: {
			sortPlayers(column) {
				console.log(`console: ${column}`);
			if (this.sortColumn === column) {
				this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
			} else {
				this.sortColumn = column;
				this.sortOrder = 'desc';
			}
			},
			toggle (id) {
				if (window.innerWidth < 700) {
					document.getElementById("bodyOverflow").style.overflow = "hidden";
				}
				this.selectedPlayer = id;
				this.playerInfoPop = true;
			},
			closePlayerInfo() {
				document.getElementById("bodyOverflow").style.overflow = "auto";
				this.playerInfoPop = false;
			}
		}
	};
</script>

<template>
	<div v-if="!playerStats.length" class="players-not-available">
		<div class="greyheader">
			<div class="greyheader-player">
				<p>{{team}}</p>
			</div>
			<div class="greyheader-value" id="grey-starts">
				<p>Avg. min.</p>
			</div>
			<div class="greyheader-value" id="grey-shots">
				<p>Shots</p>
			</div>
			<div class="greyheader-value" id="grey-target">
				<p>On target</p>
			</div>
		</div>
		<div class="message">
			<img src="../assets/img/stnd-icon.png">
			<h3>No player data available</h3>
		</div>
	</div>

	<div v-if="playerStats.length" class="greyheader">
		<div class="greyheader-player">
			<p>{{team}}</p>
		</div>
		<div class="greyheader-value" id="grey-minutes" v-bind:class="sortColumn" @click="sortPlayers('minutes')">
			<p>Min.</p>
			<img v-if="sortColumn === 'minutes'" :src="sortOrder === 'asc' ? 'img/arrow-up.png' : 'img/arrow-down.png'" class="sorting-arrow">
		</div>
		<div class="greyheader-value" id="grey-shots" v-bind:class="sortColumn" @click="sortPlayers('shots')">
			<p>Shots</p>
			<img v-if="sortColumn === 'shots'" :src="sortOrder === 'asc' ? 'img/arrow-up.png' : 'img/arrow-down.png'" class="sorting-arrow">
		</div>
		<div class="greyheader-value" id="grey-target" v-bind:class="sortColumn" @click="sortPlayers('onTarget')">
			<p>On target</p>
			<img v-if="sortColumn === 'onTarget'" :src="sortOrder === 'asc' ? 'img/arrow-up.png' : 'img/arrow-down.png'" class="sorting-arrow">
		</div>
	</div>
	<div class="list">
		<div class="player-row" v-for="playerStat in sortedPlayerStats" :key="playerStat.player.id" v-on:click="toggle(playerStat.player.id)">
			<div class="listrow-player">
				<div class="player">
					<div class="position-circle">
						<p>{{ playerStat.player.shortPosition }}</p>
					</div>
					<p>{{ playerStat.player.name }}</p>
				</div>
				<img src="../assets/img/graph.png" />
			</div>
			<div class="listrow-value player-minutes-value" v-bind:class="`player-minutes-value`">
				<p>{{ playerStat.averages.minutes }}</p>
			</div>
			<div class="listrow-value player-shots-value" v-bind:class="`player-shots-value-${playerStat.averages.shots * 10}`">
				<p>{{ playerStat.averages.shots }}</p>
			</div>
			<div class="listrow-value player-ot-shots-value" v-bind:class="`player-ot-shots-value-${playerStat.averages.onTarget * 10}`">
				<p>{{ playerStat.averages.onTarget }}</p>
			</div>
		</div>
	</div>
	<!-- <playerInfoComponent v-if="playerInfoPop" :playerId="sortedPlayerStats.player.id" :fixture="fixture" :selected_tab="selected_tab" :limit="limit" @close-player="toggle"></playerInfoComponent> -->
	<playerInfoComponent v-if="playerInfoPop" :key="this.selectedPlayer" :playerId="this.selectedPlayer" :teamPlayers="this.playerStats" :limit="this.limit" :team="this.team" @close-player="closePlayerInfo"></playerInfoComponent>
</template>

<style scoped src="../assets/css/style.css"></style>
