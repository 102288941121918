<script>
		export default {
				props: ['scoredHome', 'scoredAway', 'concededHome', 'concededAway', 'label']
		};
</script>

<template>
	<div class="stats-row">
		<div class="avg-box goals-value" v-bind:class="`stats-color-${scoredHome * 10}`">
			<p>{{ scoredHome }}</p>
		</div>
		<div class="avg-box goals-value" v-bind:class="`stats-color-${concededHome * 10}`">
			<p>{{ concededHome }}</p>
		</div>
		<div class="stats-label">
			<p>{{ label }}</p>
		</div>
		<div class="avg-box goals-value" v-bind:class="`stats-color-${scoredAway * 10}`">
			<p>{{ scoredAway }}</p>
		</div>
		<div class="avg-box goals-value" v-bind:class="`stats-color-${concededAway * 10}`">
			<p>{{ concededAway }}</p>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>
