<script>
import standingsComponent from './standingsComponent.vue'
import completeStandingsComponent from './completeStandingsComponent.vue'
import goalsComponent from './goalsComponent.vue'
import shotsComponent from './shotsComponent.vue'
import playersComponent from './playersComponent.vue'
import fixtureCountdown from './fixtureCountdown.vue'
import oddsComponent from './oddsComponent.vue'
import knockoutResultComponent from './knockoutResultComponent.vue'




export default {
	props: ['fixture', 'leagueId', 'standings', 'completeStandings', 'filterBy'],
	components: {
		standingsComponent,
		completeStandingsComponent,
		goalsComponent,
		shotsComponent,
		playersComponent,
		fixtureCountdown,
		oddsComponent,
		knockoutResultComponent
	},
	data() {
			return {
				selected_tab: 'last5',
				countdownInterval: null,
				showOddsComponent: true,
				standingsComponent: null,
				showKnockoutResult: true
			};
	},
	methods: {
		getMatchDate(matchTime) {
			var date = new Date(matchTime);
			var month = date.toLocaleString('default', { month: 'long' });
			var day = date.getDate()
			return day + " " + month
		},
		getMatchTime(matchTime) {
			var date = new Date(matchTime);
			var hours = (date.getHours() == "0" ? "00" : date.getHours())
			var minutes = (parseInt(date.getMinutes()) < 10 ? "0" + date.getMinutes() : date.getMinutes())
			return hours + ':' + minutes;
		},
		getLast5Date(last5Date) {
			var date = new Date(last5Date);
			var month = date.toLocaleString('default', { month: 'short' });
			var day = date.toLocaleString('default', { day: '2-digit' });
			return day + " " + month
		},
		updateOddsVisibility(hasOdds) {
			this.showOddsComponent = hasOdds && this.fixture.status === 'Not Started';
		},
		onKnockoutVisibilityChange(isVisible) {
			this.showKnockoutResult = isVisible;
			this.checkComponentsAvailability();
		},
		checkComponentsAvailability() {

			// Validate if standings data exists and fixture is part of supported competitions
			if (this.standings && this.standings.length > 0) {
				const competitionType = this.fixture.competition.toLowerCase();
				const roundType = this.fixture.round.toLowerCase();

				// General League handling
				if (competitionType === 'league' || competitionType.includes('cup')) {
					// Set specific conditions based on competition and round
					if (roundType.includes('relegation')) {
						this.standingsComponent = null; // Hide standings for relegation rounds
					} else if (roundType.includes('group') || roundType.includes('league')) {
						this.standingsComponent = 'standing'; // Show standings for group/league stages
					} else if (roundType.includes('knockout') || roundType.includes('round') || roundType.includes('final')) {
						this.standingsComponent = 'knockout-first'; // Show knockout stage layout
					} else if (roundType === 'final') {
						this.standingsComponent = 'final'; // Show final-specific component
					} else if (competitionType.includes('cup')) {
						this.standingsComponent = null; // Hide standings if competition does not match supported types and is cup
					} else {
						this.standingsComponent = 'standing'; // Show for league type
					}
				} else {
					this.standingsComponent = null; // Hide standings if competition does not match supported types
				}
			} else {
				this.standingsComponent = null; // Hide if no valid standings data available
			}
		},
		handleImageError(event) {
			event.target.src = require('../assets/img/DefaultTeamLogo.png'); // Path to your local image
		}
	},
	computed: {
		showCountdown() {
			const currentTime = new Date();
			const startTime = new Date(this.fixture.matchTime + "Z");
			const timeDifference = startTime - currentTime;

			if (timeDifference >= -15 * 60 * 1000 && timeDifference <= 20 * 60 * 1000) {
				return true
			} else {
				return false
			}
		},
		teamsPlaying() {
			return [this.fixture.homeTeam.name, this.fixture.awayTeam.name];
		}
	},
	watch: {
		standings() {
			this.checkComponentsAvailability();
		},
		showKnockoutResult() {
			this.checkComponentsAvailability();
		},
	},
	created() {
		this.checkComponentsAvailability();
	},
}
</script>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>


<template>
	<div class="fixture-cards">
		<div class="card" :class="{ highcard: this.filterBy === 'all' }">
			<div class="crd-header">
				<div class="time">
					<p v-if="this.filterBy === 'all'" class="time-date">{{ this.getMatchDate(fixture.matchTime + "Z") }}</p>
					<p class="time-stamp">{{ this.getMatchTime(fixture.matchTime + "Z") }}</p>
				</div>
				<div class="match-info">
					<div class="home">
						<img class="logo-flag" v-if="fixture.homeTeam.logo !== '' && this.leagueId === 87 || fixture.homeTeam.logo !== '' && this.leagueId === 281 || fixture.homeTeam.logo !== '' && this.leagueId === 127" v-bind:src="fixture.homeTeam.logo" alt="logo-home" @error="handleImageError"/>
						<img class="logo-club" v-if="fixture.homeTeam.logo !== '' && this.leagueId !== 87 || fixture.homeTeam.logo !== '' && this.leagueId !== 281 || fixture.homeTeam.logo !== '' && this.leagueId !== 127" v-bind:src="fixture.homeTeam.logo" alt="logo-home" @error="handleImageError"/>
						<div class="teamname teamnamecountry" :class="{ teamnamecountry: this.leagueId === 87 || fixture.awayTeam.logo !== '' && this.leagueId === 281 || fixture.homeTeam.logo !== '' && this.leagueId === 127 }">
							<h2>{{ fixture.homeTeam.name }}</h2>
						</div>
						<div class="livescore" v-if="fixture.status !== 'Not Started' && fixture.status !== 'Match Interrupted'">
							<p>{{ fixture.homeTeam.liveGoals }}</p>
						</div>
					</div>
					<div class="match-status">
						<fixtureCountdown v-if="showCountdown && fixture.status === 'Not Started'" :date="fixture.matchTime"></fixtureCountdown>
						<div v-else-if="fixture.status !== 'Not Started' && fixture.status !== 'Match Interrupted' && fixture.status !== 'Match Suspended' && fixture.status !== 'Match Postponed'" class="live-tag"><p>Live</p></div>
						<div v-else-if="fixture.status === 'Match Interrupted'" class="suspended-tag"><p>Interrupted</p></div>
						<div v-else-if="fixture.status === 'Match Suspended'" class="suspended-tag"><p>Suspended</p></div>
						<div v-else-if="fixture.status === 'Match Postponed'" class="postponed-tag"><p>Postponed</p></div>
						<div v-else class="vs"><h3>vs.</h3></div>
					</div>
					<div class="away" :class="{ spacebetween: fixture.status !== 'Not Started' && fixture.status !== 'Match Interrupted'}">
						<div class="livescore" v-if="fixture.status !== 'Not Started' && fixture.status !== 'Match Interrupted'">
							<p>{{ fixture.awayTeam.liveGoals }}</p>
						</div>
						<div class="teamname teamnamecountry" :class="{ teamnamecountry: this.leagueId === 87 || fixture.awayTeam.logo !== '' && this.leagueId === 281 || fixture.homeTeam.logo !== '' && this.leagueId === 127 }">
							<h2>{{ fixture.awayTeam.name }}</h2>
						</div>
						<img class="logo-flag" v-if="fixture.awayTeam.logo !== '' && this.leagueId === 87 || fixture.awayTeam.logo !== '' && this.leagueId === 281 || fixture.homeTeam.logo !== '' && this.leagueId === 127" v-bind:src="fixture.awayTeam.logo" alt="logo-away" @error="handleImageError"/>
						<img class="logo-club" v-if="fixture.awayTeam.logo !== '' && this.leagueId !== 87 || fixture.awayTeam.logo !== '' && this.leagueId !== 281 || fixture.homeTeam.logo !== '' && this.leagueId !== 127" v-bind:src="fixture.awayTeam.logo" alt="logo-away" @error="handleImageError"/>
					</div>
				</div>
			</div>
			<div class="crd-nav">
				<h3 id="tab-last5" v-on:click="selected_tab = 'last5'" v-bind:class="selected_tab">Last 5</h3>
				<h3 id="tab-goals" v-on:click="selected_tab = 'goals'" v-bind:class="selected_tab">Goals</h3>
				<h3 id="tab-shots" v-on:click="selected_tab = 'shots'" v-bind:class="selected_tab">Offense</h3>
				<h3 id="tab-players" v-on:click="selected_tab = 'players'" v-bind:class="selected_tab">Players</h3>
			</div>
			<div class="crd-tab" id="crd-tab-last5" v-if="selected_tab == 'last5'">
				<div class="last5-matches">
					<div class="matches-home">
						<div class="match" v-for="(recentFixture) in fixture.recentFixturesHome" :key="recentFixture.id">
							<div class="color" v-if="(fixture.homeTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals > recentFixture.awayGoals) || (fixture.homeTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals > recentFixture.homeGoals)" id="green"></div>
							<div class="color" v-else-if="recentFixture.homeGoals == recentFixture.awayGoals" id="grey"></div>
							<div class="color" v-if="(fixture.homeTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals < recentFixture.awayGoals) || (fixture.homeTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals < recentFixture.homeGoals)" id="red"></div>
							<div class="last5-info" :class="{ 'grey': recentFixture.homeGoals == recentFixture.awayGoals, 'green': fixture.homeTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals > recentFixture.awayGoals || fixture.homeTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals > recentFixture.homeGoals, 'red': fixture.homeTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals < recentFixture.awayGoals || fixture.homeTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals < recentFixture.homeGoals}">
								<div class="match-header">
									<!-- <div class="color" v-if="(fixture.homeTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals > recentFixture.awayGoals) || (fixture.homeTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals > recentFixture.homeGoals)" id="green"></div>
									<div class="color" v-else-if="recentFixture.homeGoals == recentFixture.awayGoals" id="grey"></div>
									<div class="color" v-if="(fixture.homeTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals < recentFixture.awayGoals) || (fixture.homeTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals < recentFixture.homeGoals)" id="red"></div> -->
									<div class="date">
										<p>{{ this.getLast5Date(recentFixture.date + "Z") }}</p>
										<p class="cup" v-if="recentFixture.competition == 'Cup'"> | {{ recentFixture.leagueName }}</p>
									</div>
								</div>
								<div class="match-result">
									<div class="score">
										<div v-bind:class = "(recentFixture.homeGoals > recentFixture.awayGoals)?'score-h win-bold':'score-h'">
											<p>{{ recentFixture.homeGoals }}</p>
										</div>
										<div v-bind:class = "(recentFixture.awayGoals > recentFixture.homeGoals)?'score-a win-bold':'score-a'">
											<p>{{ recentFixture.awayGoals }}</p>
										</div>
									</div>
									<div class="teams">
										<div v-bind:class = "(recentFixture.homeGoals > recentFixture.awayGoals)?'team-h win-bold':'team-h'">
											<p>{{ recentFixture.homeTeam.name }}</p>
										</div>
										<div v-bind:class = "(recentFixture.awayGoals > recentFixture.homeGoals)?'team-a win-bold':'team-a'">
											<p>{{ recentFixture.awayTeam.name }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="matches-away">
						<div class="match" v-for="(recentFixture) in fixture.recentFixturesAway" :key="recentFixture.id">
							<div class="last5-info" :class="{ 'grey': recentFixture.homeGoals == recentFixture.awayGoals, 'green': fixture.awayTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals > recentFixture.awayGoals || fixture.awayTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals > recentFixture.homeGoals, 'red': fixture.awayTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals < recentFixture.awayGoals || fixture.awayTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals < recentFixture.homeGoals}">
								<div class="match-header">
									<div class="date">
										<p class="cup" v-if="recentFixture.competition == 'Cup'">{{ recentFixture.leagueName }} |</p>
										<p>{{ this.getLast5Date(recentFixture.date + "Z") }}</p>
									</div>
									<!-- <div class="color" v-if="(fixture.awayTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals > recentFixture.awayGoals) || (fixture.awayTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals > recentFixture.homeGoals)" id="green"></div>
									<div class="color" v-else-if="recentFixture.homeGoals == recentFixture.awayGoals" id="grey"></div>
									<div class="color" v-if="(fixture.awayTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals < recentFixture.awayGoals) || (fixture.awayTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals < recentFixture.homeGoals)" id="red"></div> -->
								</div>
								<div class="match-result">
									<div class="teams">
										<div v-bind:class = "(recentFixture.homeGoals > recentFixture.awayGoals)?'team-h win-bold':'team-h'">
											<p>{{ recentFixture.homeTeam.name }}</p>
										</div>
										<div v-bind:class = "(recentFixture.awayGoals > recentFixture.homeGoals)?'team-a win-bold':'team-a'">
											<p>{{ recentFixture.awayTeam.name }}</p>
										</div>
									</div>
									<div class="score">
										<div v-bind:class = "(recentFixture.homeGoals > recentFixture.awayGoals)?'score-h win-bold':'score-h'">
											<p> {{ recentFixture.homeGoals }}</p>
										</div>
										<div v-bind:class = "(recentFixture.awayGoals > recentFixture.homeGoals)?'score-a win-bold':'score-a'">
											<p> {{ recentFixture.awayGoals }} </p>
										</div>
									</div>
								</div>
							</div>
							<div class="color" v-if="(fixture.awayTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals > recentFixture.awayGoals) || (fixture.awayTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals > recentFixture.homeGoals)" id="green"></div>
							<div class="color" v-else-if="recentFixture.homeGoals == recentFixture.awayGoals" id="grey"></div>
							<div class="color" v-if="(fixture.awayTeam.name == recentFixture.homeTeam.name && recentFixture.homeGoals < recentFixture.awayGoals) || (fixture.awayTeam.name == recentFixture.awayTeam.name && recentFixture.awayGoals < recentFixture.homeGoals)" id="red"></div>
						</div>
					</div>
				</div>
				<standingsComponent 
					v-if="standingsComponent === 'standing'"
					:standings="standings">
				</standingsComponent>
				<div class="bottom-btn" v-if="standingsComponent === 'standing'" v-on:click="selected_tab = 'completeStanding'" v-bind:class="selected_tab">
					<p>View full standings</p>
				</div>
				<knockoutResultComponent 
					v-if="standingsComponent === 'knockout-first'"
					:fixture="fixture.id" 
					:leagueId="leagueId"
					:round="fixture.round"
					@knockoutVisibility="onKnockoutVisibilityChange">
				</knockoutResultComponent>
				<div v-if="standingsComponent === null" class="stnd-not-available">
					<div class="greyheader">
						<div class="stnd-name">
							<p class="title">Standings</p>
						</div>
					</div>
					<div class="message">
						<img src="../assets/img/stnd-icon.png">
						<h3>No standings available</h3>
					</div>
				</div>
				<div v-if="standingsComponent === 'final'" class="stnd-not-available">
					<div class="greyheader">
						<div class="stnd-name">
							<p class="title">The Final</p>
						</div>
					</div>
					<div class="message">
						<img src="../assets/img/cup.png">
						<h3>Who will raise the cup?</h3>
					</div>
				</div>
			</div>
			<completeStandingsComponent 
				:completeStandings="completeStandings" 
				:selected_tab="selected_tab" 
				:teams-playing="teamsPlaying">
			</completeStandingsComponent>
			<div class="bottom-btn bottom-btn-back" v-if="selected_tab == 'completeStanding'" v-on:click="selected_tab = 'last5'" v-bind:class="selected_tab">
				<img src="../assets/img/arrow-left.png" class="back-arrow"><p>Back to last 5</p>
			</div>
			<KeepAlive>
				<goalsComponent v-if="selected_tab === 'goals'" :fixture="fixture.id" :selected_tab="selected_tab" :limit="$root.limit"></goalsComponent>
			</KeepAlive>
			<KeepAlive>
				<shotsComponent v-if="selected_tab === 'shots'" :fixture="fixture.id" :selected_tab="selected_tab" :limit="$root.limit"></shotsComponent>
			</KeepAlive>
			<KeepAlive>
				<playersComponent v-if="selected_tab === 'players'" :fixture="fixture.id" :selected_tab="selected_tab" :limit="$root.limit" :teamsPlaying="teamsPlaying"></playersComponent>
			</KeepAlive>
		</div>
		<oddsComponent 
			v-if="showOddsComponent"
			:fixture="fixture.id" 
			:selected_tab="selected_tab" 
			:limit="$root.limit"
			@updateOddsVisibility="updateOddsVisibility">
		</oddsComponent>
	</div>

</template>
