

<template>
	<div class="matches skeleton-lge">
		<div class="fixture-list">
			<div class="fixture-cards">
				<div class="card">
					<div class="crd-header skeleton-header">
						<div class="time skeleton skeleton-time"></div>
						<div class="match-info">
							<div class="home">
								<div class="teamname skeleton skeleton-text skeleton-team"></div>
							</div>
							<div class="match-status skeleton-status"></div>
							<div class="away">
								<div class="teamname skeleton skeleton-text skeleton-team"></div>
							</div>
						</div>
					</div>
					<div class="crd-tab skeleton skeleton-tab"></div>
				</div>
				<div class="odd-card">
					
				</div>
			</div>
			<div class="fixture-cards">
				<div class="card">
					<div class="crd-header skeleton-header">
						<div class="time skeleton skeleton-time"></div>
						<div class="match-info">
							<div class="home">
								<div class="teamname skeleton skeleton-text skeleton-team"></div>
							</div>
							<div class="match-status skeleton-status"></div>
							<div class="away">
								<div class="teamname skeleton skeleton-text skeleton-team"></div>
							</div>
						</div>
					</div>
					<div class="crd-tab skeleton skeleton-tab"></div>
				</div>
				<div class="odd-card">
					
				</div>
			</div>
			<div class="fixture-cards">
				<div class="card">
					<div class="crd-header skeleton-header">
						<div class="time skeleton skeleton-time"></div>
						<div class="match-info">
							<div class="home">
								<div class="teamname skeleton skeleton-text skeleton-team"></div>
							</div>
							<div class="match-status skeleton-status"></div>
							<div class="away">
								<div class="teamname skeleton skeleton-text skeleton-team"></div>
							</div>
						</div>
					</div>
					<div class="crd-tab skeleton skeleton-tab"></div>
				</div>
				<div class="odd-card">

				</div>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css">
/* @import '../assets/css/startpage.css'; */
</style>
