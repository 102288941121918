<script>
		export default {
			props: ['cardsTotalYellowHomeReceived', 'cardsTotalYellowHomeOpponent', 'cardsTotalRedHomeReceived', 'cardsTotalRedHomeOpponent', 'cardsTotalYellowAwayReceived', 'cardsTotalYellowAwayOpponent', 'cardsTotalRedAwayReceived', 'cardsTotalRedAwayOpponent', 'label'],
			methods: {
				calculateFontWeightYellow(value) {
					if (value <= 0.5) {
						return 200;
					} else if (value <= 1.5) {
						return 300;
					} else if (value <= 2.5) {
						return 400;
					} else {
						return 500;
					}
				},
				calculateFontWeightRed(value) {
					if (value <= 0.2) {
						return 200;
					} else if (value <= 0.4) {
						return 300;
					} else if (value <= 0.6) {
						return 400;
					} else {
						return 500;
					}
				}
			}
		};
</script>

<template>
	<div class="stats-row stats-row-cards">
		<div class="cards-home">
			<!-- <div class="yellow-cards" :class="{ cardsApplicable: previousFixtureData?.data?.away?.statistics?.yellowCards > 0 }"> -->
			<div class="yellow-cards cardsApplicable">
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightYellow(cardsTotalYellowHomeReceived) }">{{ cardsTotalYellowHomeReceived }}</h3></div>
				<div class="card-yellow"></div>
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightYellow(cardsTotalYellowHomeOpponent) }">{{ cardsTotalYellowHomeOpponent }}</h3></div>
			</div>
			<div class="red-cards" :class="{ cardsApplicable: cardsTotalRedHomeReceived >= 0.1 || cardsTotalRedHomeOpponent >= 0.1 }">
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightRed(cardsTotalRedHomeReceived) }">{{ cardsTotalRedHomeReceived }}</h3></div>
				<div class="card-red"></div>
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightRed(cardsTotalRedHomeOpponent) }">{{ cardsTotalRedHomeOpponent }}</h3></div>
			</div>
		</div>
		<div class="stats-label">
			<p>{{ label }}</p>
		</div>
		<div class="cards-home">
			<div class="yellow-cards cardsApplicable">
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightYellow(cardsTotalYellowAwayReceived) }">{{ cardsTotalYellowAwayReceived }}</h3></div>
				<div class="card-yellow"></div>
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightYellow(cardsTotalYellowAwayOpponent) }">{{ cardsTotalYellowAwayOpponent }}</h3></div>
			</div>
			<div class="red-cards" :class="{ cardsApplicable: cardsTotalRedAwayReceived >= 0.1 || cardsTotalRedAwayOpponent >= 0.1 }">
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightRed(cardsTotalRedAwayReceived) }">{{ cardsTotalRedAwayReceived }}</h3></div>
				<div class="card-red"></div>
				<div class="card-label"><h3 :style="{ fontWeight: calculateFontWeightRed(cardsTotalRedAwayOpponent) }">{{ cardsTotalRedAwayOpponent }}</h3></div>
			</div>
		</div>
	</div>
</template>

<style scoped src="../assets/css/style.css"></style>